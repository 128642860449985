import { useQuery } from '@tanstack/react-query'
import { FC, useMemo } from 'react'
import { sortBy } from 'lodash'

import {
    Button,
    NumberInput,
    SelectInput,
    SelectMultipleInput,
    TextLink,
} from 'ds/components'

import { formatCurrency } from 'lib/utils/formatCurrency'
import { formatDateShort } from 'lib/utils/formatDateShort'
import { getRemainingAmountToPay } from '../utils'
import { castDecimal } from 'lib/utils/castDecimal'
import { invoiceService } from 'lib/services/invoiceService'
import { InvoicePaymentItem } from 'lib/models/InvoicePaymentItem'
import { InputInfo } from 'ds/components/InputInfo'
import { InvoiceV2 } from 'lib/models/invoice'
import { CurrencyKeyValue } from 'lib/common/CurrencyKeyValue'
import { Currency } from 'lib/models/currency'

type Props = {
    disabled: boolean
    formData: any
    invoiceCurrency: string | undefined
    currencyError: string | undefined
    invoicePaymentsError: string | undefined

    handleChange: (key: string, value: any) => void
}

export const SelectInvoicesInputLoader: FC<Props> = ({
    formData,
    disabled,
    invoiceCurrency,
    currencyError,
    invoicePaymentsError,
    handleChange,
}) => {
    const queryParams = useMemo(
        () => ({
            components: 'cfdi,contact',
            customer_id: formData.customer?.customer_id,
            settled_state: 'not_settled',
        }),
        [formData.customer?.customer_id]
    )

    const { data } = useQuery({
        queryKey: ['invoice', queryParams],
        queryFn: () => invoiceService.getInvoices(queryParams),
        enabled: !!queryParams.customer_id && !disabled,
    })

    const invoices: InvoiceV2[] = useMemo(
        () => sortBy(data?.invoices ?? [], (i) => i.time_due),
        [data]
    )

    const selectedInvoices: InvoiceV2[] = useMemo(
        () => sortBy(formData.invoices, (i) => i.time_due),
        [formData.invoices]
    )
    const invoicePayments: InvoicePaymentItem[] = formData.invoice_payments

    // Exclude selected invoices in selects
    const availableInvoices = useMemo(() => {
        return invoices.filter(
            (invoice) =>
                !selectedInvoices.some(
                    (selected) => selected.invoice_id === invoice.invoice_id
                )
        )
    }, [invoices, selectedInvoices])

    const totalAmountPendingSelectedInvoices = useMemo(() => {
        if (currencyError || !selectedInvoices.length) return undefined
        const diffCurrency = invoiceCurrency !== formData.currency

        const amountTotalPending = selectedInvoices.reduce((acc, invoice) => {
            const amount_pending = getRemainingAmountToPay(invoice)
            return acc.plus(castDecimal(amount_pending))
        }, castDecimal(0))

        const total = formatCurrency(
            amountTotalPending.toNumber(),
            2,
            diffCurrency ? invoiceCurrency : undefined
        )

        const amountPendingExchange =
            diffCurrency && formData.exchange_dr
                ? castDecimal(amountTotalPending).div(formData.exchange_dr).toNumber()
                : ''
        const totalExchange = amountPendingExchange
            ? formatCurrency(amountPendingExchange, 2, formData.currency)
            : ''

        return `Total Seleccionado: ${total}${
            totalExchange ? ` o ${totalExchange} con intercambio ingresado` : ''
        }`
    }, [
        currencyError,
        invoiceCurrency,
        selectedInvoices,
        formData.currency,
        formData.exchange_dr,
    ])

    const handleChangeToManualMode = () => {
        let remainingAmount = castDecimal(formData.amount_paid)

        // Initialize invoice payments when switching to manual mode
        const initialInvoicePayments: InvoicePaymentItem[] = selectedInvoices.map(
            (invoice) => {
                const amountOutstanding = getRemainingAmountToPay(invoice)
                const amountApplied = amountOutstanding.greaterThan(remainingAmount)
                    ? remainingAmount.toNumber()
                    : amountOutstanding.toNumber()

                remainingAmount = remainingAmount.minus(amountApplied)

                return new InvoicePaymentItem(
                    invoice.invoice_id,
                    amountOutstanding.toNumber(),
                    amountApplied > 0 ? amountApplied : null
                )
            }
        )
        handleChange('invoice_payments', initialInvoicePayments)
        handleChange('split_payment_manually', true)
    }

    const handleRemoveInvoicePayment = (id: string) => {
        const index = invoicePayments.findIndex((item) => item.id === id)
        handleRemoveInvoice(invoicePayments[index]?.invoice_id)
        invoicePayments.splice(index, 1)
        handleChange('invoice_payments', invoicePayments)
        // Reset
        if (invoicePayments.length === 1) handleChange('split_payment_manually', false)
    }

    const handleRemoveInvoice = (invoice_id: number | null) => {
        if (!invoice_id) return
        const updatedInvoices = selectedInvoices.filter(
            (item) => item.invoice_id !== invoice_id
        )
        handleChange('invoices', updatedInvoices)
    }

    const handleAddInvoice = (invoice_id: number | null) => {
        if (!invoice_id) return
        const invoice = invoices.find((item) => item.invoice_id === invoice_id)
        if (!invoice) return
        const updatedInvoices = [...selectedInvoices, invoice] // Create a new array so React detects change in selectedInvoices
        handleChange('invoices', updatedInvoices)
    }

    const handleAddInvoicePayment = () => {
        invoicePayments.push(new InvoicePaymentItem())
        handleChange('invoice_payments', invoicePayments)
    }

    const handleChangeInvoicePayment = ({
        id,
        field,
        value,
    }: {
        id: string
        field: string
        value: number | null
    }) => {
        const index = invoicePayments.findIndex((item) => item.id === id)
        invoicePayments[index][field] = value

        // Set amount outstanding
        if (field == 'invoice_id') {
            handleAddInvoice(value)
            // This is cleaner than a useEffect
            const invoiceIdx = invoices.findIndex((item) => item.invoice_id === value)
            const invoice = invoices[invoiceIdx]
            const amountOutstanding = getRemainingAmountToPay(invoice).toNumber()
            invoicePayments[index]['amount_outstanding'] = amountOutstanding
        }

        handleChange('invoice_payments', invoicePayments)
    }

    const renderInvoicePaymentRow = (
        invoicePayment: InvoicePaymentItem,
        index: number
    ) => (
        <div
            key={invoicePayment.id}
            className="d-flex gap-2 stacked-sm align-items-center"
        >
            <SelectInput
                required
                disabled={disabled}
                label={index === 0 ? 'Cobro' : undefined}
                className="col-4"
                value={selectedInvoices.find(
                    (invoice) => invoice.invoice_id === invoicePayment.invoice_id
                )}
                items={availableInvoices}
                getItemDescription={getItemDescription}
                getItemId={(o) => o.invoice_id}
                itemToString={(o) => `Cobro ${o.invoice_num}`}
                onChange={(o) =>
                    handleChangeInvoicePayment({
                        id: invoicePayment.id,
                        field: 'invoice_id',
                        value: o.invoice_id,
                    })
                }
            />
            <CurrencyKeyValue
                amount={invoicePayment.amount_outstanding}
                className="col-2"
                currency={Currency.MXN}
                label={index === 0 ? 'Pendiente' : undefined}
            />
            <CurrencyKeyValue
                amount={
                    (invoicePayment.amount_outstanding ?? 0) -
                    (invoicePayment.amount ?? 0)
                }
                className="col-2"
                currency={Currency.MXN}
                label={index === 0 ? 'Restante' : undefined}
            />
            <NumberInput
                required
                label={index === 0 ? 'Monto' : undefined}
                className="col-2"
                variant="currency"
                value={invoicePayment.amount}
                onChange={(value) =>
                    handleChangeInvoicePayment({
                        id: invoicePayment.id,
                        field: 'amount',
                        value,
                    })
                }
            />
            {index !== 0 && !disabled ? (
                <div className="col-2">
                    <Button
                        size="sm"
                        variant="secondaryText"
                        onClick={() => handleRemoveInvoicePayment(invoicePayment.id)}
                        className="d-flex align-items-center"
                    >
                        <ion-icon name="close-outline" />
                    </Button>
                </div>
            ) : null}
        </div>
    )

    if (formData.split_payment_manually && selectedInvoices.length > 1) {
        return (
            <div className="stacked-sm align-center">
                {invoicePayments.map((invoicePayment, index) =>
                    renderInvoicePaymentRow(invoicePayment, index)
                )}
                {!disabled ? (
                    <Button
                        size="sm"
                        variant="primaryText"
                        className="inline-md stacked-md"
                        icon="plus-lg"
                        onClick={handleAddInvoicePayment}
                    >
                        Agregar
                    </Button>
                ) : null}
                <InputInfo error={invoicePaymentsError} />
            </div>
        )
    }

    const renderHelp = () => {
        if (!totalAmountPendingSelectedInvoices) return null
        return (
            <div className="d-flex gap-2">
                <span>{totalAmountPendingSelectedInvoices}</span>
                {selectedInvoices.length > 1 ? (
                    <>
                        -
                        <TextLink onClick={() => handleChangeToManualMode()}>
                            Divide monto entre cobros manualmente
                        </TextLink>
                    </>
                ) : null}
            </div>
        )
    }

    return (
        <SelectMultipleInput
            className="stacked-md"
            disabled={disabled}
            emptyText={
                !queryParams.customer_id
                    ? 'Selecciona un cliente'
                    : 'No hay cobros pendientes para este cliente'
            }
            error={currencyError}
            getItemDescription={getItemDescription}
            getItemId={(o) => o.invoice_id}
            help={renderHelp()}
            items={invoices}
            itemToString={(o) => `Cobro ${o.invoice_num}`}
            label="Aplicar a cobros"
            selectedItems={selectedInvoices}
            setSelectedItems={(v) => handleChange('invoices', v)}
        />
    )
}

const getItemDescription = (o: any) => {
    const timeDue = formatDateShort(o.time_due)
    const amount = formatCurrency(o.amount_total, 2, o.currency)
    const amountLeft = formatCurrency(getRemainingAmountToPay(o), 2, o.currency)
    return `${timeDue} · ${o.order_detail} · ${amount} · Pendiente ${amountLeft}`
}
