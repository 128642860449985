import { format } from 'date-fns'
import { AccountLanguage } from 'lib/models/account'
import { getAccountLocale } from './getAccountLocale'

export const formatDateShort = (date: any, accountLanguage?: AccountLanguage) => {
    if (!date) return date
    const d = new Date(date)
    if (isNaN(d.getTime())) return date

    const locale = getAccountLocale(accountLanguage)

    // ex. 3 oct 2022 or Oct 3, 2022
    return format(d, 'PP', { locale })
}
