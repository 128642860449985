import Decimal from 'decimal.js'
import { InvoicePaymentItem } from 'lib/models/InvoicePaymentItem'
import { BankTransaction } from 'lib/models/bankTransaction'
import { castDecimal } from 'lib/utils/castDecimal'
import { dateToDateString } from 'lib/utils/dateStringUtils'
import { isValidIngresoCfdi } from 'lib/utils/isValidIngresoCfdi'
import { Invoice, InvoiceV2 } from 'lib/models/invoice'
import { Currency } from 'lib/models/currency'
export const getInitAmount = ({
    invoices,
    bankTransaction,
}: {
    invoices?: any[]
    bankTransaction?: BankTransaction
}): number => {
    if (bankTransaction) return Number(bankTransaction.amount) || 0
    const numInvoices = invoices?.length || 0
    if (!numInvoices || !invoices) return 0

    return invoices
        .reduce(
            (acc, invoice) => acc.plus(getRemainingAmountToPay(invoice)),
            castDecimal(0)
        )
        .toNumber()
}

export const getRemainingAmountToPay = (invoice: any): Decimal => {
    if (!invoice) return castDecimal(0)
    return castDecimal(invoice.amount_total).minus(castDecimal(invoice.amount_paid))
}

type GetInitCurrencyParams = {
    invoice?: Invoice | InvoiceV2
    bankTransaction?: BankTransaction
    primaryCurrency: Currency
}

export const getInitCurrency = ({
    invoice,
    bankTransaction,
    primaryCurrency,
}: GetInitCurrencyParams): Currency => {
    if (invoice) return invoice.currency
    if (bankTransaction) return bankTransaction.currency

    return primaryCurrency
}

export const getInitDate = (bankTransaction: BankTransaction | undefined): string =>
    bankTransaction?.transacted_at
        ? dateToDateString(new Date(bankTransaction.transacted_at))
        : dateToDateString(new Date())

export const getDefaultPaymentFormCode = ({
    isCredit,
    account,
}: {
    isCredit: boolean
    account: any
}): string => {
    if (isCredit) return '15'

    if (account.cfdi_payment_form && account.cfdi_payment_form !== '99') {
        return account.cfdi_payment_form
    }

    return '03'
}

type Params = {
    invoices: any[]
    invoice_payments?: InvoicePaymentItem[]
    split_payment_manually?: boolean
    amount_paid: number
    exchange_dr: number
}

export const getInvoicePaymentsMap = ({
    invoices,
    invoice_payments,
    split_payment_manually,
    amount_paid,
    exchange_dr,
}: Params): Record<number, number> => {
    if (!invoices?.length || !amount_paid) return {}

    if (invoice_payments && split_payment_manually) {
        return getInvoicePaymentsMapBasedInvoicePayments(invoice_payments)
    }

    return getInvoicePaymentsMapBasedOnInvoices({
        invoices,
        amount_paid,
        exchange_dr,
    })
}

const getInvoicePaymentsMapBasedOnInvoices = ({
    invoices,
    amount_paid,
    exchange_dr,
}: Params): Record<number, number> => {
    let runningAmount = castDecimal(amount_paid)
        .times(exchange_dr || 1)
        .toDecimalPlaces(2)

    const invoicePaymentMap = invoices
        // Sort by oldest
        .sort((a, b) => Date.parse(a.time_due) - Date.parse(b.time_due))
        .reduce((map, invoice) => {
            const remainingAmountToPay = getRemainingAmountToPay(invoice)
            let amount_applied = castDecimal(0)
            if (remainingAmountToPay.lessThan(runningAmount)) {
                amount_applied = remainingAmountToPay
            } else if (remainingAmountToPay.greaterThanOrEqualTo(runningAmount)) {
                amount_applied = runningAmount
            }

            map[invoice.invoice_id] = amount_applied.toNumber()
            runningAmount = runningAmount.minus(amount_applied)
            return map
        }, {})

    return invoicePaymentMap
}

const getInvoicePaymentsMapBasedInvoicePayments = (
    invoice_payments: InvoicePaymentItem[]
): Record<number, number> => {
    if (!invoice_payments?.length) return {}
    const map = {}
    invoice_payments.forEach(({ invoice_id, amount }) => {
        if (invoice_id) {
            map[invoice_id] = amount
        }
    })

    return map
}

export const getInvoicesWithApplicableCfdis = (invoices: any[], cfdiType: 'P' | 'E') =>
    invoices.filter((invoice) => {
        const applicableCfdis = invoice.cfdis.filter((cfdi) => {
            return cfdiType === 'E'
                ? isValidIngresoCfdi(cfdi)
                : isApplicableForCfdiPago(cfdi)
        })
        return !!applicableCfdis.length
    })

const isApplicableForCfdiPago = (cfdi: any): boolean => isValidIngresoCfdi(cfdi, 'PPD')

export const getMultipleCfdisError = (invoices: any[], isCredit = false): string[] =>
    invoices
        .map((o) => {
            const invoiceCfdis = o.cfdis.filter((cfdi) =>
                isCredit ? isValidIngresoCfdi(cfdi) : isApplicableForCfdiPago(cfdi)
            )
            const invoiceCfdiCount = invoiceCfdis.length
            return invoiceCfdiCount > 1
                ? `Cobro ${o.invoice_num} tiene ${invoiceCfdiCount} facturas.`
                : ''
        })
        .filter((o) => o)

export const getCfdiIds = (invoices: any[], isCredit = false): number[] =>
    invoices
        .map((invoice) => {
            const applicableCfdis = invoice.cfdis.filter((cfdi) =>
                isCredit ? isValidIngresoCfdi(cfdi) : isApplicableForCfdiPago(cfdi)
            )
            return applicableCfdis[0].cfdi_id
        })
        .filter(Boolean)
