import { FC, useContext, useMemo } from 'react'

import { Option, Select, SelectButtons, OptionButton } from 'ds/components'

import { FormContext } from 'lib/hooks/useForm'
import { SessionContext } from 'lib/hoc/withSession'
import { Currency, getCurrencyOptions } from 'lib/models/currency'

type Props = {
    className?: string
    currency: string | null
    error?: string
    required?: boolean
    showAllOption?: boolean
    disabled?: boolean
    formContext?: FormContext
    isFilter?: boolean
    onChange: (currency: string) => void
}

export const CurrencySelect: FC<Props> = ({
    className,
    required,
    showAllOption,
    currency,
    error,
    onChange,
    disabled,
    formContext,
    isFilter,
}) => {
    const { primaryCurrency, invoiceCurrencies } = useContext(SessionContext)

    const currencyOptions = useMemo(
        () => (isFilter ? invoiceCurrencies : getCurrencyOptions(primaryCurrency)),
        [primaryCurrency, isFilter, invoiceCurrencies]
    )

    return (
        <Select
            className={className}
            disabled={disabled || currencyOptions.length < 2}
            error={error}
            formContext={formContext}
            label="Moneda"
            onChange={onChange}
            required={required}
            value={currency || ''}
        >
            {currencyOptions.map((cur) => (
                <Option key={cur} value={cur}>
                    {cur}
                </Option>
            ))}
            {showAllOption ? <Option value="all">Todas</Option> : null}
        </Select>
    )
}

type SelectButtonsProps = {
    className?: string
    label?: string
    currency: Currency | null
    onChange: (currency: Currency | null) => void
    showAllOption?: boolean
}

export const CurrencySelectButtons: FC<SelectButtonsProps> = ({
    className,
    label,
    currency,
    onChange,
    showAllOption,
}) => {
    const { invoiceCurrencies } = useContext(SessionContext)

    if (invoiceCurrencies.length < 2) return null

    return (
        <SelectButtons
            className={className}
            value={currency || ''}
            label={label}
            onChange={(val) => onChange((val || null) as Currency | null)}
        >
            {showAllOption ? <OptionButton value="">Todo</OptionButton> : null}
            {invoiceCurrencies.map((cur) => (
                <OptionButton key={cur} value={cur}>
                    {cur}
                </OptionButton>
            ))}
        </SelectButtons>
    )
}
