import { Cfdi } from 'lib/models/cfdi'
import { Contact } from 'lib/models/contact'
import { Customer } from 'lib/models/customer'
import { Item } from 'lib/models/item'
import { Organization } from 'lib/models/organization'
import { PaginationMetadata } from 'lib/models/paginationMetadata'
import {
    InvoiceInstallment,
    PaymentPlan,
    PaymentPlanInvoice,
    PaymentPlanTableItem,
} from 'lib/models/paymentPlan'
import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const baseKey = 'payment-plan'
export const paymentPlanQueryKey = [baseKey]

export const paymentPlanServiceKeys = {
    invalidateAll: paymentPlanQueryKey,
    getPaymentPlans: (params?: GetPaymentPlansParams) => [baseKey, params],
    getPaymentPlan: (payment_plan_id: number) => [baseKey, payment_plan_id],
    getNextReferenceNum: [baseKey, 'next-reference-num'],
}

const url = '/payment-plans'

export const paymentPlanService = {
    getPaymentPlans: async (queryParams: GetPaymentPlansParams) => {
        return makeApiRequest<GetPaymentPlansData>({ url, queryParams })
    },
    getPaymentPlan: async ({ paymentPlanId }: { paymentPlanId: number }) => {
        return makeApiRequest<GetPaymentPlanData>({ url: `${url}/${paymentPlanId}` })
    },
    getNextReferenceNum: async () =>
        makeApiRequest<GetNextReferenceNumData>({ url: `${url}/next-reference-num` }),
    postPaymentPlan: async (body: PostPaymentPlanBody) => {
        return makeApiRequest({ url, method: 'POST', body })
    },
    deletePaymentPlan: async (body: DeletePaymentPlanBody) => {
        return makeApiRequest({
            url: `${url}/${body.payment_plan_id}`,
            method: 'DELETE',
            body,
        })
    },
}

type GetPaymentPlansParams = {
    customer_id?: number
    organization_id?: number
    search?: string
    limit?: number
    offset?: number
    sort_by?: string
    tag_ids?: number[]
    status?: string
}

type GetPaymentPlansData = {
    payment_plans: PaymentPlanTableItem[]
    pagination_metadata: PaginationMetadata
}

type GetPaymentPlanData = {
    payment_plan: PaymentPlan
    customer: Customer
    organization: Organization
    contact: Contact
    documents: Document[]
    payment_plan_invoices: PaymentPlanInvoice[]
    payment_plan_tag_ids: number[]
    items: Item[]
    cfdis: Cfdi[]
    invoices_have_cfdis: boolean
}

type PostPaymentPlanBody = {
    // General info
    organization_id: number
    customer_id: number
    contact_id: number
    description: string
    series: string
    currency: 'MXN' | 'USD'
    note: string
    reference_num: string
    items: Item[]
    tag_ids: number[]

    // Config
    start_date: string
    payment_terms: string
    down_payment: number
    installment_count: number
    payment_frequency: 'MONTHLY'
    payment_distribution: 'EQUAL' | 'CUSTOM'
    annual_interest_rate: number
    apply_iva_tax_to_interest: boolean

    // Amounts
    amount_principal_subtotal: number
    amount_principal_tax: number
    amount_principal_withholding: number
    amount_principal_total: number

    amount_interest_before_tax: number
    amount_interest_tax: number
    amount_interest_total: number

    amount_total: number

    // Invoice installments
    invoice_installments: InvoiceInstallment[]
}

type DeletePaymentPlanBody = {
    payment_plan_id: number
}

type GetNextReferenceNumData = {
    next_reference_num: number
}
