import { v4 } from 'uuid'

export class InvoicePaymentItem {
    id: string
    invoice_id: number | null
    amount: number | null
    amount_outstanding: number | null

    constructor(
        invoice_id?: number,
        amount_outstanding?: number,
        amount_applied?: number | null
    ) {
        this.id = v4()
        this.invoice_id = invoice_id || null
        this.amount = amount_applied || null
        this.amount_outstanding = amount_outstanding || null
    }
}
