import { Incoterm } from 'lib/models/comercioExteriorComplement'
import { Item } from 'lib/models/item'
import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/cfdis'

export const cfdiService = {
    getAll: async (queryParams) => makeApiRequest({ url, queryParams }),
    postCfdi: async (body) => makeApiRequest({ url, method: 'POST', body }),
    postInvoiceCfdiIngreso: async (body) =>
        makeApiRequest({ url: `${url}/invoice`, method: 'POST', body }),
    postSubscriptionCfdiIngreso: async (body) =>
        makeApiRequest({ url: `${url}/subscription`, method: 'POST', body }),
    postPaymentPlanCfdiIngreso: async (
        body: PostPaymentPlanCfdiIngresoBody
    ): Promise<PostPaymentPlanCfdiIngresoData> =>
        makeApiRequest({ url: `${url}/payment-plan`, method: 'POST', body }),
    postCfdiPagoPreview: async (body) =>
        makeApiRequest({ url: `${url}/payment`, method: 'POST', body }),
    postCfdiPagoExistingPayment: async (body) =>
        makeApiRequest({ url: `${url}/existing-payment`, method: 'POST', body }),
    postCfdiEgreso: async (body) =>
        makeApiRequest({ url: `${url}/credit`, method: 'POST', body }),
    replaceCfdi: async ({
        body,
        cfdi_id,
    }: ReplaceCfdiPayload): Promise<ReplaceCfdiData> =>
        makeApiRequest({ url: `${url}/${cfdi_id}/replace`, method: 'POST', body }),
    getCfdiSync: async ({ cfdi_id }) =>
        makeApiRequest<CfdiSyncResponse>({ url: `${url}/${cfdi_id}/sync` }),
    bulkDownload: async ({ cfdi_ids }) => {
        await downloadFile({
            url: `${url}/bulk-download`,
            queryParams: { cfdi_ids },
            filename: 'cfdis.zip',
        })
    },
    getCsvDownloadCfdis: async (queryParams) => {
        const filename = `cfdis-${formatDateTimestamp(new Date())}.csv`
        await downloadFile({
            url: `${url}/csv-download`,
            queryParams,
            filename,
        })
    },
    cancelCfdi: async ({
        cfdi_id,
        motive,
        substitution,
        type,
        delete_invoice_on_cancel,
    }: CancelCfdiPayload) => {
        const typeUrl = type === 'P' || type === 'E' ? 'payment/' : ''
        return makeApiRequest<CfdiSyncResponse>({
            url: `${url}/${typeUrl}${cfdi_id}`,
            method: 'DELETE',
            body: { motive, substitution, delete_invoice_on_cancel },
        })
    },
}

type CfdiSyncResponse = {
    invoiceIdDeleted: number | undefined
}

type CancelCfdiPayload = {
    cfdi_id: number
    motive: string
    substitution: string
    type: 'P' | 'I' | 'E'
    delete_invoice_on_cancel: boolean
}

type ReplaceCfdiPayload = {
    cfdi_id: number
    body: {
        items: Item[]
        invoice_id: number
        organization_id: number
        amount_subtotal: number
        amount_tax: number
        amount_withholding: number
        amount_total: number

        custom_issuance_date?: string
        exchange?: number
        payment_method: string
        use: string
        payment_form: string

        periodicity?: string
        months?: string
        year?: string | number

        related_documents?: any[]
        mode: 'preview' | 'live'
        ine_complement?: any
        comercio_exterior_incoterm?: Incoterm
        comercio_exterior_ratios?: Record<number, number>
    }
}

export type ReplaceCfdiData = {
    success: true
    data: any
    message: string
    pdf_url: string
    xml_url: string
    zip_url?: string
    cfdi_id?: number
    error?: string
}

export type PostPaymentPlanCfdiIngresoBody = {
    payment_plan_id: number
    custom_issuance_date?: string
    exchange?: number
    use: string
    payment_form: string
    related_documents?: any[]
    mode: 'preview' | 'live'
    send_email: boolean
}

type PostPaymentPlanCfdiIngresoData = {
    success: true
    message: string
    data: any
    pdf_url: string
    xml_url: string
    zip_url?: string
    cfdi_id?: number
    error?: string
}
