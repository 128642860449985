import { AccountLanguage } from 'lib/models/account'
import { NormalizedPayment } from 'lib/models/normalizedPayment'
import { Payment } from 'lib/models/payment'
import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/payments'

export const paymentService = {
    getPayments: async (queryParams) => makeApiRequest({ url, queryParams }),
    downloadPayments: async (queryParams) => {
        const filename = `pagos-${formatDateTimestamp(new Date())}.csv`

        await downloadFile({
            url,
            queryParams: { ...queryParams, download: true },
            filename,
            throwError: true,
        })
    },
    downloadPaymentPdf: (
        payment: Payment | NormalizedPayment,
        language: AccountLanguage
    ) => {
        const { payment_id, confirmation, reference_num } = payment

        const filename = language === 'en' ? 'receipt' : 'recibo'

        return downloadFile({
            url: `${url}/${payment_id}/receipt`,
            filename: `${filename}-${confirmation || reference_num}.pdf`,
        })
    },
    getPayment: async ({ payment_id }) => makeApiRequest({ url: `${url}/${payment_id}` }),
    postPayment: async (body) => makeApiRequest({ url, method: 'POST', body }),
    delete: async (body) =>
        makeApiRequest({
            url: `${url}/${body.payment_id}`,
            method: 'DELETE',
            body,
        }),
}
