import { formatDate } from './formatDate'
import { shortenStringMiddle } from './shortenStringMiddle'

export const getSeriesAndInvoiceNum = ({ series, invoice_num }) => {
    return [series, invoice_num].filter(Boolean).join(' ')
}

const getSatTransactionSeriesAndInvoiceNum = ({ cfdi_serie, cfdi_folio }) => {
    return [cfdi_serie, cfdi_folio].filter(Boolean).join(' ')
}

export const getSatTransactionInfo = (transaction, shortenDescription = true) => {
    const folioInfo = getSatTransactionSeriesAndInvoiceNum(transaction)
    let description = shortenDescription
        ? shortenStringMiddle(transaction.description, 30)
        : transaction.description

    const taxId = transaction.extra?.tax_id
    if (taxId) description += ` (${taxId})`

    const uniqueId = folioInfo || formatDate(transaction.transacted_at, 'dd/MM/yy')

    return [uniqueId, description].filter(Boolean).join(' - ')
}
