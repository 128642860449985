import { FC, useMemo } from 'react'
import { subHours } from 'date-fns'

import { DatePicker, Input } from 'ds/components'

import { dateStringToDate } from 'lib/utils/dateStringUtils'

type Props = {
    formData: any
    handleChange: (key: 'issuanceDate' | 'operationNum', value: any) => void
}

export const PagoCfdiOptions: FC<Props> = ({ formData, handleChange }) => {
    const { minDate, maxDate } = useMemo(
        () => ({
            minDate: subHours(new Date(), 72),
            maxDate: new Date(),
        }),
        []
    )

    return (
        <>
            <DatePicker
                label="Fecha de emisión"
                className="col-3"
                value={dateStringToDate(formData.issuanceDate)}
                onDateStringChange={(value) => handleChange('issuanceDate', value)}
                minDate={minDate}
                maxDate={maxDate}
            />
            <Input
                className="col-3"
                label="Número de operación"
                value={formData.operationNum}
                onChange={(val) => handleChange('operationNum', val)}
            />
        </>
    )
}
